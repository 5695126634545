(function(window) {
	/**
	 * Small debug helper
	 *
	 * @param {Boolean} hasDebug Do we debug or not?
	 */
	function logger(hasDebug, name) {
		var debug = {};

		if (hasDebug) {
			for (var m in window.console) {
				if (typeof window.console[m] == 'function') {
					debug[m] = window.console[m].bind(window.console, name);
				}
			}
		} else {
			for (var m in window.console) {
				if (typeof window.console[m] == 'function') {
					debug[m] = function() {};
				}
			}
		}

		return debug;
	}

	// Expose globally
	window.logger = logger;
})(window);
